$color-primary: #000000;
$color-point1: #3177FF;
$color-white: #fff;
$font-size-default: 14px;

$layout-max-width: 1080px;
$layout-min-width: 280px;

$header-max-width: 1080px;
$header-min-width: 280px;

$font-white: LeferiPoint-WhiteA;
$font-regular: LeferiBaseType-RegularA;
$font-bold: LeferiBaseType-BoldA;

ul, li.infoList li {
  list-style: none;
}

.img_wrapper {
  position: relative;
}

.screen_mobile {
  display: none;
}

.partners_container {
  box-sizing: border-box;
  height: auto; /* 1185px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  font-family: $font-regular;
  letter-spacing: -0.05em;
}

.partners_container section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 130px 0;
  background-color: #fff;
}

.partners_container section h2 {
  color: #222;
  font-size: 40px;
  font-family: $font-bold;
  padding-bottom: 130px;
  text-align: center;
}

.partners_container section.main {
  background-color: #f1f3f9;
}

.partners_container section.main .partners_hero {
  width: 100%;
  max-width: 1080px;
}

.partners_container section.main .type_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1040px;
}

.partners_container section.main .type_wrapper .type {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.partners_container section.main .type_wrapper .type img {
  // width: 100%;
}

.partners_container section.main .type_wrapper .type .title {
  font-size: 24px;
  font-family: $font-bold;
  margin-bottom: 10px;
}

.partners_container section.main .type_wrapper .type .text_wrapper {
  padding: 20px 20px 25px;
}

.partners_container section.main .type_wrapper .type .text_wrapper .desc {
  margin-left: 25px;
  line-height: 175%;
}

.partners_container section.main .type_wrapper .type .text_wrapper .desc li {
  list-style: disc !important;
}

.partners_container section.growth {
  background-color: #151F33;
}

.partners_container section.growth h2 {
  font-family: $font-regular;
  font-weight: 400;
  color: #fff;
}

.partners_container section.growth h2 .bold {
  font-family: $font-bold;
}

.partners_container section.growth .logo-wrapper {
  display: flex;
  gap: 100px;
  margin-bottom: 150px;
}

.partners_container section.growth .logo-wrapper img {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 120px;
}

.partners_container section.growth .logo-wrapper img:nth-child(2) {
  padding: 0 14px;
}

.partners_container section.growth .growth-wrapper {
  display: flex;
  gap: 40px;
}

.partners_container section.growth .growth-wrapper .growth-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  max-width: 330px;
  border-radius: 20px;
  padding: 40px 20px;
}

.partners_container section.growth .growth-wrapper .growth-item .title {
  font-size: 20px;
  font-family: $font-bold;
  text-underline-offset: 4px;
  text-decoration: underline;
  margin-bottom: 10px;
}

.partners_container section.growth .growth-wrapper .growth-item .desc {
  font-size: 14px;
  text-align: center;
  color: #397df9;
}

.partners_container section.growth .growth-wrapper .growth-item img {
  width: 100%;
}

.partners_container section.commission {
  max-height: 1300px;
}

.partners_container section.commission h2 {
  z-index: 10;
}

.partners_container section.commission img {
  width: 100%;
  max-width: 1080px;
  position: relative;
  z-index: 1;
  top: -25em;
}

.partners_container section.commission .box_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  z-index: 10;
  position: relative;
  bottom: 48em;
  width: 100%;
  max-width: 1080px;
}

.partners_container section.commission .box_container div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.30);
  font-size: 18px;
}

.partners_container section.commission .box_container .box2,
.partners_container section.commission .box_container .box3 {
  font-family: $font-bold;
}

.partners_container section.commission .box_container .box1,
.partners_container section.commission .box_container .box2,
.partners_container section.commission .box_container .box3 {
  background-color: #f1f3f9;
  border-top: 1px solid rgba(0, 0, 0, 0.30);
}

.partners_container section.commission .box_container .box4,
.partners_container section.commission .box_container .box8,
.partners_container section.commission .box_container .box10 {
  background-color: #f0f0f0;
}

.partners_container section.commission .box_container .box5,
.partners_container section.commission .box_container .box6,
.partners_container section.commission .box_container .box7,
.partners_container section.commission .box_container .box9,
.partners_container section.commission .box_container .box11 {
  background-color: #fff;
}

.partners_container section.commission .box_container .box4 {
  grid-row: span 2 / span 2;
  height: 140px;
}

.partners_container section.commission .box_container .box7 {
  grid-column: span 2 / span 2;
  grid-column-start: 2;
}

.partners_container section.commission .box_container .box8 {
  grid-row-start: 4;
}

.partners_container section.commission .box_container .box9 {
  grid-column: span 2 / span 2;
  grid-row-start: 4;
}

.partners_container section.commission .box_container .box10 {
  grid-row-start: 5;
}

.partners_container section.commission .box_container .box11 {
  grid-column: span 2 / span 2;
  grid-row-start: 5;
}

.partners_container section.flow {
  background-color: #f1f3f9;
}

.partners_container section.flow img {
  width: 100%;
  max-width: 1440px;
}

.partners_container section.start_partner .partner_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  width: 100%;
  max-width: 1080px;
  margin-bottom: 50px;
  box-shadow: -1px 6px 30px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 10px;
}

.partners_container section.start_partner .partner_container .content_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: calc(100% / 3);
}

.partners_container section.start_partner .partner_container .content_wrapper .column {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: $font-bold;
  padding: 20px 0;
  background-color: #0056ff;
  color: white;
}

.partners_container section.start_partner .partner_container .content_wrapper.open_market {
  border-right: 1px solid #c4ceff;
}

.partners_container section.start_partner .partner_container .content_wrapper.special_market {
  border-left: 1px solid #c4ceff;
}

.partners_container section.start_partner .partner_container .content_wrapper .partners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 0;
  padding: 30px;
  width: 100%;
  max-width: 1080px;
}

.partners_container section.start_partner .partner_container .content_wrapper .partners .img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 130px;
  max-height: 48px;
}

.partners_container section.start_partner .partner_container .content_wrapper .partners .img-wrapper img {
  height: 50px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

.partners_container section.start_partner .partner_container .content_wrapper.general_market .partners .img-wrapper:nth-child(2) img {
  height: 30px;
}

.partners_container section.start_partner .partner_container .content_wrapper.general_market .partners .img-wrapper:nth-child(4) img {
  height: 35px;
}

.partners_container section.start_partner .partner_container .content_wrapper.general_market .partners .img-wrapper:nth-child(5) img,
.partners_container section.start_partner .partner_container .content_wrapper.general_market .partners .img-wrapper:nth-child(7) img {
  height: 20px;
}

.partners_container section.start_partner .partner_container .content_wrapper.special_market .partners .img-wrapper:nth-child(2) img {
  height: 17px;
}

.partners_container section.start_partner .info_desc {
  font-size: 20px;
  width: 100%;
  max-width: 1080px;
  color: #397df9;
  text-align: right;
  margin-bottom: 130px;
}

.partners_container section.start_partner .contact_box {
  width: 100%;
  max-width: 1080px;
  padding: 50px;
  border-radius: 30px;
  background-color: #3355ff;
}

.partners_container section.start_partner .contact_box h2 {
  color: #fff;
  padding-bottom: 50px;
}

.partners_container section.start_partner .contact_box .contact_wrapper {
  display: flex;
  gap: 50px;
}

.partners_container section.start_partner .contact_box .contact_wrapper span {
  width: 100%;
  padding: 10px 0;
  font-size: 20px;
  border-radius: 20px;
  color: #3355ff;
  text-decoration: none;
  text-align: center;
  font-family: $font-bold;
  background-color: #fff;
}

@media screen and (max-width: 1080px) {
  .partners_container section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .partners_container section.main .type_wrapper {
    gap: 50px;
  }

  .partners_container section.commission {
    max-height: 85em;
  }

  .partners_container section.commission .box_container div {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .mobileNavigation nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  .partners_container section.main .type_wrapper .type {
    max-width: 420px;
  }

  .partners_container section.commission .box_container div {
    padding: 15px;
  }
}

@media screen and (max-width: 820px) {
  .partners_container section.main .type_wrapper .type {
    max-width: 335px;
  }

  .partners_container section.growth .logo-wrapper {
    gap: 60px;
  }

  .partners_container section.growth .growth-wrapper {
    gap: 20px;
  }

  .partners_container section.commission {
    max-height: 70em;
  }

  .partners_container section.commission img {
    top: -20em;
  }

  .partners_container section.commission .box_container {
    bottom: 38em;
  }

  .partners_container section.commission .box_container div {
    padding: 15px;
  }

  .partners_container section.flow picture {
    text-align: center;
  }

  .partners_container section h2 {
    padding-bottom: 80px;
  }

  .partners_container section.flow img {
    width: 70%;
  }
}

@media screen and (max-width: 768px){
  .screen_mobile {
    display: block;
  }

  .partners_container section {
    overflow: hidden;
    padding: 100px 20px;
  }

  .partners_container section h2 {
    font-size: 24px;
    padding-bottom: 60px;
  }

  .partners_container section.main .partners_hero {
    width: 470px;
  }

  .partners_container section.main .type_wrapper {
    width: 100%;
    max-width: unset;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .partners_container section.main .type_wrapper .type {
    max-width: 100%;
  }

  .partners_container section.main .type_wrapper .text_wrapper .title {
    font-size: 16px;
  }

  .partners_container section.main .type_wrapper .text_wrapper .desc {
    font-size: 14px;
  }

  .partners_container section.main .type_wrapper .type.shop {
    margin-top: 15px;
  }

  .partners_container section.growth .logo-wrapper {
    width: 100%;
    margin-bottom: 80px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  .partners_container section.growth .logo-wrapper img:nth-child(2) {
    padding: 0 14px;
  }

  .partners_container section.growth .growth-wrapper .growth-item .title {
    font-size: 16px;
  }

  .partners_container section.growth .growth-wrapper .growth-item .desc {
    font-size: 14px;
  }

  .partners_container section.growth .growth-wrapper {
    flex-direction: column;
    gap: 50px;
  }
  .partners_container section.commission {
    max-height: 90em;
  }
  
  .partners_container section.commission img {
    width: 870px;
    left: 50%;
    transform: translateX(-50%);
    top: -16em;
  }

  .partners_container section.commission .box_container {
    grid-template-columns: 1fr 2fr 2fr;
    bottom: 28em;
  }

  .partners_container section.commission .box_container div {
    font-size: 14px;
  }

  .partners_container section.commission .box_container .box5,
  .partners_container section.commission .box_container .box6,
  .partners_container section.commission .box_container .box7,
  .partners_container section.commission .box_container .box9,
  .partners_container section.commission .box_container .box11 {
    font-size: 13px;
  }

  .partners_container section.flow img {
    width: 85%;
  }

  .partners_container section.start_partner {
    overflow: unset;
  }

  .partners_container section.start_partner .partner_container {
    flex-direction: column;
    gap: 50px;
    box-shadow: unset;
    border-radius: unset;
    padding-bottom: 30px;
    margin-bottom: 0;
  }

  .partners_container section.start_partner .partner_container .content_wrapper {
    box-shadow: -1px 6px 30px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 330px;
    margin: 0 auto;
    overflow: hidden;
  }

  .partners_container section.start_partner .partner_container .content_wrapper .partners {
    padding: 20px;
  }

  .partners_container section.start_partner .partner_container .content_wrapper.open_market,
  .partners_container section.start_partner .partner_container .content_wrapper.special_market {
    border: unset;
  }

  .partners_container section.start_partner .partner_container .content_wrapper .column {
    font-size: 16px;
    padding: 15px 0;
  }

  .partners_container section.start_partner .partner_container .content_wrapper.open_market .partners {
    grid-template-rows: repeat(3, 1fr);
  }

  .partners_container section.start_partner .partner_container .content_wrapper.special_market .partners {
    grid-template-rows: repeat(1, 1fr);
  }

  .partners_container section.start_partner .info_desc {
    width: 330px;
  }

  .partners_container section.start_partner .partner_container {
    grid-template-columns: repeat(1, 1fr);
  }

  .partners_container section.start_partner .contact_box {
    padding: 40px;
    max-width: 380px;
  }

  .partners_container section.start_partner .contact_box h2 {
    padding-bottom: 30px;
  }

  .partners_container section.start_partner .contact_box .contact_wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .partners_container section.start_partner .contact_box .contact_wrapper span,
  .partners_container section.start_partner .info_desc {
    font-size: 16px;
  }
}

@media screen and (max-width: 420px) {
  
}


@media screen and (max-width: 280px) {
  
}