$color-primary: #000000;
$color-point1: #3177FF;
$color-white: #fff;
$font-size-default: 14px;

$layout-max-width: 1080px;
$layout-min-width: 280px;

$header-max-width: 1080px;
$header-min-width: 280px;

$font-white: LeferiPoint-WhiteA;
$font-regular: LeferiBaseType-RegularA;
$font-bold: LeferiBaseType-BoldA;

.bannerContainer {
  width: 100%;
  height: 600px;
  //background-color: rgba(55, 200, 200, 0.5);
  background-color: $color-white;
  margin-top: -88px;
  overflow: hidden;
  background-size: cover;
  position: relative;
}

.bannerImage{
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center bottom;

}

.bannerContent{
  /* max-width: 1080px; */
  /* min-width: 240px; */
  /* min-height: 600px; */
  //margin-top: 88px;
  margin: 0 auto;
  /* position: relative; */
  top: 44px;
  max-width: 1080px;
  height: 600px;
  display: flex;
  flex-direction: row;
  padding-top: 44px;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  //padding: 0 20px 0 20px;
}

.banner {
  //position: absolute;
  //right: 0px;
  //top: 120px;
  width:360px;
  height:360px;
  z-index: 10;
  border-radius: 16px;
}

.bannerText {
  flex: 0 1 calc(100% - 480px);
  z-index: 10;
}

.bannerText > h1 {
  font-family: $font-bold;
  color: $color-white;
  font-size: 40px;
  letter-spacing: -0.8px;
  line-height: 1.4;
  //text-align: center;
  font-weight: 400;
  word-break: keep-all;
  margin-bottom: 50px;
}
.bannerText > h2 {
  font-family: $font-bold;
  color: $color-white;
  font-size: 20px;
  letter-spacing: -0.8px;
  line-height: 1.4;
  //text-align: center;
  font-weight: 400;
  margin-bottom: 10px;
}
.bannerText > h3 {
  font-family: $font-regular;
  color: $color-white;
  font-size: 14px;
  letter-spacing: -0.8px;
  line-height: 1.4;
  //text-align: center;
  font-weight: 400;
  margin-bottom: 40px;

}




.bannerBlur {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 120%;
  right: -30px;
  bottom: -30px;
  background-size: cover;
  backdrop-filter: blur(50px);
  background-color: rgba(0,0,0,0.3);
  height:660px;
}


.banner_button {
  width: 130px;
  height: 50px;
  border-radius: 8px;
  background-color:transparent;
  border: 1px solid $color-white;
  font-family: $font-bold;
  color: $color-white;
  cursor: pointer;
  font-size: 16px;
}

.banner_button:hover {
  background: $color-primary;
  border: 1px solid $color-primary;
}


.buttonList_event {
  box-sizing: border-box;
  flex-shrink: 0;
  width: auto; /* 508px */
  height: auto; /* 166px */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 60px 0px;
}

.eventsPage .subPage {padding-bottom: 120px;}

.event_type_button {
  background: $color-white;
  border: 0;
  padding: 0 20px 0 20px;
  margin: 0 4px;
  font-size: 16px;
  height: 44px;
  letter-spacing: -0.06em;
  font-family: $font-regular;
  cursor: pointer;
  color: #000;
}

.event_type_button:hover,
.event_type_button.active{
  background: $color-primary;
  color: $color-white;
  border-radius: 8px;
  font-family: $font-bold;
}

.event_type_button_active {
  background: $color-primary;
  color: $color-white;
  border: 0;
  padding: 0 20px 0 20px;
  font-size: 16px;
  height: 44px;
  border-radius: 8px;
  font-family: $font-bold;
}

.event_dropdown {
  box-sizing: border-box;
  flex-shrink: 0;
  height: auto; /* 70px */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 30px 0;
  overflow: visible;
}

.dropdown {
  padding: 0 16px 0 12px;
  box-sizing: border-box;
  font-size: 14px;
  flex-shrink: 0;
  width: 108px;
  height: 40px;
  text-align: left;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $color-primary;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('./images/select_arrow@2x.png') no-repeat right 12px top 50%;
  background-size: 8px 6px;
}

.dropdown_value {
  width: auto; /* 54px */
  height: auto; /* 14px */
  white-space: pre;
  font-family: $font-regular;
  color: $color-primary;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1;
  text-align: center;
}

.eventContainer {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  margin: 0 auto 0 auto;
  flex-wrap: wrap;
  gap: 60px;
}

.eventItem {
  flex: 0 1 calc(33.33333% - 40px);
  position:relative;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0;
}

.eventItemLink {
  display: block;
  width: 100%;
  height: 100%;
}

.eventImage {
  display: block;
  width: 100%;
  height: 100%;
}

.eventEndDate {
  position: absolute;
  padding: 8px 12px;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-family: $font-regular;
  background: rgba(0, 0, 0, 0.5);
  /* padding-top: 45%; */
  border-radius: 16px;
  line-height: 1;
  text-align: center;
}

.eventAlwaysDate {
  position: absolute;
  padding: 8px 12px;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-family: $font-regular;
  background: rgba(0, 0, 0, 0.5);
  /* padding-top: 45%; */
  border-radius: 16px;
  line-height: 1;
  text-align: center;
}

.endEvent {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 24px;
  background: rgba(0,0,0,0.5);
  padding-top: 45%;
}


.add_button_section{
  width: calc(100% - 40px);
  max-width: 420px;
  margin: 0 auto;
  text-align: center;
  //margin-bottom: 120px;
}


.add_button {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: visible;
  border-radius: 8px;
  border: 1px solid $color-primary;
  font-family: $font-bold;
  background: $color-white;
  cursor: pointer;
  margin-top: 60px;
  color: #000;
}
.add_button:hover {background: #000; color: #fff; transition: all 0.3s;}

.process_Event {
  font-size: 24px;
  font-family: $font-bold;
  letter-spacing: -0.8px;
  line-height: 1.4;
  margin-top: 120px;
  margin-bottom: 30px;
}

.eventDetail{
  padding: 0 0;
}

.detailDom img {
  width: 100%;
  max-width: 700px;
}
.detailDom iframe {width: 100%; max-width: 740px;}

.pageBack {
  position: relative;
  display: flex;
  justify-content: center;
  //margin-top: 40px;
  //margin-bottom: 40px;
  margin: 40px 20px 40px 20px;
}

.pageBackButton {
  box-sizing: border-box;
  max-width: 480px;
  min-width: 240px;
  width: 100%;
  height: 50px;
  overflow: visible;
  border-radius: 8px;
  border: 1px solid #000000;
  font-family: $font-bold;
  background: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  text-decoration: none;
  color: #000;
}

.pageBackButton:hover
{
  background: $color-primary;
  color: $color-white;
  border-radius: 8px;
  font-family: $font-bold;
}

.detailSubject {
  //padding: 0 20px;
  font-size: 32px;
  font-family: $font-bold;
  letter-spacing: -0.8px;
  line-height: 1.4;
  margin-top: 60px;
}

.detailDate {
  padding: 20px 0 0 0;
  font-size: 20px;
  color: #7a8599;
  font-family: $font-white;
  letter-spacing: 0;
  line-height: 1.4;
  //margin-left: 20px;
}

.skele{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.detailDom{
  margin-top: 60px;
  text-align: center;
}

@media screen and (max-width: 1080px) {
 .eventContainer {
   justify-content: space-between;
   padding: 0 20px;
 }

 .bannerContent {
   padding: 0 20px 0 20px;
 }

  .detailSubject {
    padding: 0 20px;
    font-size: 28px;
    letter-spacing: -0.8px;
    line-height: 1.4;
    word-break: keep-all;

  }

  .detailDate {
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.4;
    margin-top: 20px;
    word-break: keep-all;
  }



}

@media screen and (max-width: 1018px) {

  .intro2_slide {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .eventContainer {
    justify-content: flex-start;
    gap: 20px;

    .eventItem {
      flex: 0 1 calc(50% - 10px);
    }
  }
  .skele {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(100% + 60px);
    margin: 0 auto 0 -60px;
    flex-wrap: wrap;
  }
  .skele span:last-child{
    display: none;
  }

  .bannerContent {
    .banner {
      width: 320px;
      height: auto;
    }
  }

  .bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 400px;
  }

  .bannerBlur {
    height: 560px;
  }


  .banner{
    margin-bottom: -170px;
  }

  .bannerText {
    text-align: center;
    margin-bottom: 60px;
  }
  //
  //.bannerContent {
  //  margin: 0 30px;
  //}

}

@media screen and (max-width: 480px) {
  .bannerText > h1 {
    margin-bottom: 40px;
    font-size: 28px;
  }
  .eventsPage .buttonList_event {padding-top: 20px;}
  .detailDom img {
    width: 100% !important;
    height: auto !important;
    max-width: 700px;
  }
}

@media screen and (max-width: 768px) {
  .bannerText > h1 {
    margin-bottom: 40px;
    font-size: 28px;
  }
  .eventsPage .buttonList_event {padding-top: 20px;}
  .bannerContainer {
    height: 720px;
    .bannerContent {
      height: 530px;
    }
  }
  .bannerText > h2 {
    margin-top: 100px;
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  .eventContainer {
    justify-content: center;

    .eventItem {
      flex: 0 1 calc(100%);
    }
  }
  .bannerContainer {
    height: 640px;
    .bannerContent {
      height: 300px;
    }
  }

  .bannerContent {
    .banner {
      width: 240px;
      height: auto;
    }
  }
}

@media screen and (max-width: 280px) {
  .bannerContainer {
    height: 680px;
  }
  .event_type_button {font-size: 14px;}
}